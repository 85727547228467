import {createApi} from "@reduxjs/toolkit/query/react";
import {secureBaseQuery} from "./auth";
import {authURL} from "../Config";
// import urlParamsBuilder from "../utils/urlParamsBuilder";
import onQueryStarted from "./helpers/onQueryStarted";

export const tournamentsApi = createApi({
    reducerPath: 'tournamentApi',
    tagTypes: ['Tournaments'],
    baseQuery: secureBaseQuery({
        baseUrl: `${authURL}`,
    }),
    endpoints: (builder) => {
        let endpoints = {};
        //list endpoint
        endpoints.getList = builder.query(
            {
                query: () => {
                    return {
                        url: '/tournaments?pagination=false&order[sortOrder]=asc'
                    }
                },
                providesTags: ['Tournaments'],
            }
        );
        //update from chess results
        endpoints.update = builder.mutation({
            query: (payload) => ({
                url: `/tournaments/${payload.id}/update/${payload.round || 1}`,
                method: 'PATCH',
                body: JSON.stringify(payload),
            }),
            invalidatesTags: ['Tournaments'],
            // transformResponse: (response) => response,
            onQueryStarted,
        })
        //get item
        endpoints.get = builder.query({
            query: (id) => ({
                url: `/tournaments/${id}`,
                method: 'GET',
            }),
            providesTags: ['Tournaments'],
        })
        //get TournamentRound
        endpoints.getTournamentRound = builder.query({
            query: (payload) => ({
                url: `/tournaments/${payload.tournamentId}/${payload.roundId || 1}`,
                method: 'GET',
            }),
            providesTags: ['Tournaments'],
        })
        //delete item
        endpoints.delete = builder.mutation({
            query: ({id}) => ({
                url: `/tournaments/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Tournaments'],
            onQueryStarted,
        });
        endpoints.pdf = builder.query({
            query: ({id, round=1}) => ({
                url: `/tournaments/${id}/${round}/pdf`,
                method: 'GET',
            }),
            providesTags: ['Tournaments'],
        })
        return endpoints;
    }
});

export const {
    useLazyGetListQuery,
    useLazyGetQuery,
    useLazyGetTournamentRoundQuery,
    useUpdateMutation,
    useLazyPdfQuery,
} = tournamentsApi;
