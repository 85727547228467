import React from 'react';
import { FooterWrapper, FooterContainer } from './Footer.style';
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';


const Footer = ({ className = '' }) => (
  <FooterWrapper className={`mt-auto ${className}`}>
    <FooterContainer className='h-50 d-flex flex-md-row flex-column justify-content-around align-items-lg-end align-items-md-center'>
      <div className='text-center order-md-1 order-2'>
        <p className='pb-1'>
            <span><FormattedMessage id={'app.footer.copyright'} values={{year: new Date().getFullYear()}} /></span>
        </p>
      </div>
    </FooterContainer>
  </FooterWrapper>
);

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
