import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';
import localizations from '../I18n'
import { defaultLocale } from '../Config';
import languages from "../Config/languages";
import lscache from "lscache";
import PropTypes from 'prop-types';

const IntlContext = React.createContext(defaultLocale);

const IntlWrapper = (props) => {
    const [locale, setLocale] = useState(lscache.get('locale') ?? defaultLocale);
    const [messages, setMessages] = useState(localizations[locale]);
    function changeLanguage(language) {
        if (languages[language]) {
            setLocale(language);
            setMessages(localizations[language])
            lscache.set('locale', language);
        }
    }
    return (
        <IntlContext.Provider value = {{locale, changeLanguage}}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </IntlContext.Provider>
    );
}

IntlWrapper.propTypes = {
    children: PropTypes.element
};
export {
    IntlContext,
    IntlWrapper
}
