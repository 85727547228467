import React from 'react';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const Loading = ({ width=5, height=5, className}) => (
    <Spinner
    // animation={animation}
    className={`d-flex m-5 align-self-center ${className}`}
      style={{
        height: `${width}rem`,
        width: `${height}rem`,
      }}
    />
);

Loading.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string
};

export default Loading;
