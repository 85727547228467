import React, {useContext, useRef, useState} from 'react';

import {IntlContext} from "../../Context/IntlWrapper";
import languages from "../../Config/languages";
import ButtonFactory from '../UploadButton/ButtonFactory';
import LanguagesBox from './Components/LanguagesBox';
import clsx from 'clsx';
import { downwardsChevron, upwardsChevron } from '../UploadButton/Assets/Assets';
import useClickingOutside from '../../Hooks/useClickingOutside';

const LanguageSwitch = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  
  const { locale, changeLanguage } = useContext(IntlContext);
  const dropdownRef = useRef()

  useClickingOutside(dropdownRef, setDropdownOpen)

  const setLanguage = (language) => changeLanguage(language)
  const flagStyle = clsx(`
    language-box-row-icon`,
    locale === 'en' && "english-flag-bg",
    locale === 'ro' && "romanian-flag-bg"
  )
  
  return (
    <div ref={dropdownRef}>
      <ButtonFactory onClick={toggle} variant="languageSwap">
        <>
          <div className={flagStyle} />
          {languages[locale].label}
          {dropdownOpen ? upwardsChevron : downwardsChevron}
        </>
      </ButtonFactory>
      <LanguagesBox setLanguage={setLanguage} setDropdownOpen={setDropdownOpen} isDropdownOpen={dropdownOpen} />
    </div>
  )
}

export default LanguageSwitch;
