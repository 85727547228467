import {NotFoundPage, Home, LoginPage, ResultsPage} from "./Lazy";

const routes = {
    public: [
        {  element: ResultsPage.view(), index: true},
        { path: 'login', element: LoginPage.view() },
        { path: 'not-found', element: NotFoundPage.view() },
        { path: '*', element: NotFoundPage.view() }
    ],
    private: [
        { element: Home.view(), path: "home" }
    ]
}

export default routes;
