import React, { useRef, useState } from 'react';
import { browseIcon } from '../../UploadButton/Assets/Assets';
import LanguageAndLogoutBox from './LanguageAndLogoutBox';
import useClickingOutside from '../../../Hooks/useClickingOutside';

const HamburgerMenu = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const mobileRef = useRef()
  useClickingOutside(mobileRef, setDropdownOpen)
  return (
    <div onClick={toggle} className='mobile-dropdown-button' ref={mobileRef}>
      <div className="">
        {browseIcon}
      </div>
      {
        <LanguageAndLogoutBox toggle={toggle} isOpen={dropdownOpen}/>
      }
    </div>
  );
}

export default HamburgerMenu;
