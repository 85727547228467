import {
  isRejected,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { setError } from './features/errors/errorsSlice';
import {getErrors} from "../utils/apiUtils";

const errorHandlerMiddleware = (api) => (next) => (action) => {
  if (isRejectedWithValue(action) || isRejected(action)) {
    const message = getErrors(action?.payload?.data)
      || ['general.msg'];

    api.dispatch(setError({ message }));
  }

  next(action);
};

export default errorHandlerMiddleware;
