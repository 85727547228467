import {setCookie, retrieveCookie, deleteCookie} from "../utils/cookieUtils";

const parseJwt = (token) => {
    let jsonPayload = JSON.stringify({ userId: null, isAdmin: false });

    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
                .join(''),
        );
    } catch (_) {
        jsonPayload = JSON.stringify({ userId: null, isAdmin: false, roles: [] });
    }

    return JSON.parse(jsonPayload);
};

const getToken = () => {
    return retrieveCookie('token');
};
const setToken = (token) => {
    setCookie('token', token);
};
const deleteToken = () => {
    deleteCookie('token')
};
const getRefreshToken = () => {
    return retrieveCookie('refreshToken')
}
const setRefreshToken = (token) => {
    setCookie('refreshToken', token);
}
const deleteRefreshToken = () => {
    deleteCookie('refreshToken');
}
const deleteAll = () => {
    deleteToken();
    deleteRefreshToken()
}
const getUserData = () => {
    let token = getToken();
    if (token) {
        return parseJwt(token);
    }
    return null;
}

const tokenRepository = {
    getToken,
    setToken,
    getUserData,
    deleteToken,
    getRefreshToken,
    deleteRefreshToken,
    deleteAll,
    setRefreshToken,
    parseJwt
}
export default tokenRepository;
