import { createGlobalStyle } from 'styled-components';
import { Colors, Sizes } from './Theme';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-toastify/dist/ReactToastify.css';

const GlobalStyle = createGlobalStyle`
  html {
    font-family: 'Plus Jakarta Sans', sans-serif;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    position: relative;
    color: rgba(0,0,0,0.84);
    margin: 0;
    padding: 0;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    background: ${Colors.background};
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    line-height: 30px;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  button {
    outline: none;
  }

  button.page-link:focus {
    outline: none;
    box-shadow: none;
  }

  .fs-10 {
    font-size: 0.7rem !important;
    text-align: center;
  }

  .disabled-with-opacity {
    opacity: 0.5;
  }

  .m0-auto {
    margin: 0 auto;
  }

  .bi-gradient {
    background: linear-gradient(90deg, #4591F9 0%, #04ECF6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bi-border-gradient {
    background: #fff;
    padding: 1rem;
    border-radius: 8px;
    margin: 0 0 3rem;

    p {
      padding: 0 1.3rem;
      margin-top: 0;
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -3px;
      border-radius: inherit;
      background: linear-gradient(90deg, #4591F9 0%, #04ECF6 100%);
    }
  }

  .hp-notice {
    background: ${Colors.darkText};
    padding: 1rem;
    border-radius: 8px;
    margin: 0 0 3rem;
    color: ${Colors.light};
    font-size: ${Sizes.default};
    line-height: 1.7;

    p {
      margin: 0;
    }

    a {
      color: ${Colors.accent};
    }
  }

  .bi-closeIcon {
    font-size: 1.5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  .bi-slightLeft {
    margin: 0 0 0 .3rem;
  }

  .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      filter: drop-shadow(0px 14.4037px 21.6055px rgba(17, 17, 17, 0.16));
      border-radius: 14px;
    }
  }

  .stripe-pricing-table {
    width: 60%;
    @media (max-width: 1199px) {
      width: 100%;
    }
  }

  .flex-stripe {
    @media (min-width: 960px) {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 992px) {
    .mobile-scroll-overflow-x {
      overflow-x: scroll;
    }

    .mobile-overflow-scroll-y {
      overflow-y: scroll;
    }
  }

  .hide-on-mobile {
    @media (max-width: 1199px) {
      display: none;
    }
  }
`;

export default GlobalStyle;
