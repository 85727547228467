import {lazy} from "react";
import {UserRoles} from "../Config/UserRoles";
import Loadable from "../Components/Loadable";

export const Home = {
    view: Loadable(lazy(() => import('../View/Homepage'))),
    permissions: [UserRoles.ADMIN, UserRoles.USER],
    path: '/',
};

export const ResultsPage = {
    view: Loadable(lazy(() => import('../View/Results'))),
    permissions: [],
    path: '/results',
};

export const NotFoundPage = {
    view: Loadable(lazy(() => import('../View/NotFound'))),
    permissions: [],
    path: '/not-found',
};
export const LoginPage = {
    view: Loadable(lazy(() => import('../View/Login'))),
    permissions: [],
    path: '/login',
}; 

export const routes = {
    Home,
    NotFoundPage,
    ResultsPage,
    LoginPage
};
