import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from '../Redux/features/auth/authSlice';
import errorSliceReducer from '../Redux/features/errors/errorsSlice';
import { RESET_STATE_ACTION_TYPE } from '../Redux/actions/index';
import {authApi} from "../Services/auth";
import {tournamentsApi} from "../Services/tournament";
import {publicTournamentApi} from "../Services/publicTournament";
import {gamesApi} from "../Services/game";
import errorHandlerMiddleware from './ErrorHandlerMiddleware';
import tournamentsReducer from "./features/tournament/tournamentsSlice";

const reducers = {
  [authApi.reducerPath]: authApi.reducer,
  [tournamentsApi.reducerPath]: tournamentsApi.reducer,
  [publicTournamentApi.reducerPath]: publicTournamentApi.reducer,
  [gamesApi.reducerPath]: gamesApi.reducer,
  auth: authReducer,
  errorSlice: errorSliceReducer,
  tournament: tournamentsReducer 
};

const combinedReducers = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {};
  }

  return combinedReducers(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    authApi.middleware,
    tournamentsApi.middleware,
    publicTournamentApi.middleware,
    gamesApi.middleware,
    errorHandlerMiddleware,
  ),
});

export { store };
