import React, {memo, useState} from 'react';
import { Outlet } from 'react-router-dom';
import { DefaultLayoutStyle } from './DefaultLayout.style';
import Footer from "../../Footer";
import {FlashMessage} from "../../FlashMessage/FlashMessage";
import {Header} from '../../Header';

const DefaultLayout = () => {
    const [messages, setMessages] = useState([]);
    return (
        <DefaultLayoutStyle className='d-flex flex-column min-vh-100 ps-2 pe-2 background-gray'>
            <Header />
            <FlashMessage messages={messages} key={'flash-messages'}>
                <Outlet/>
            </FlashMessage>
            <Footer/>
        </DefaultLayoutStyle>
    )
};

export default memo(DefaultLayout);
