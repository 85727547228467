import './App.css';

import { Provider } from 'react-redux';
import { AuthProvider } from "./Context/AuthContext";
import { IntlWrapper } from "./Context/IntlWrapper";
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from "./Routes";
import {store} from "./Redux/store";
import {BroadcastLogoutAllTabs} from "./Services/auth";
import {useEffect} from "react";
import GlobalStyle from "./Styles/GlobalStyles";
import { Toaster } from 'react-hot-toast';

const App = () => {
  useEffect(() => {
    BroadcastLogoutAllTabs();
  }, []);

  return (
      <Provider store={store}>
        <Toaster/>
        <AuthProvider>
          <GlobalStyle />
          <IntlWrapper>
            <BrowserRouter>
              <AppRoutes />

            </BrowserRouter>
          </IntlWrapper>
        </AuthProvider>
      </Provider>
  );
};

export default App;
