import React, {useMemo, useState} from "react";
import {cloneDeep, isString} from "lodash";
import MessageItem from "./MessageItem";
import PropTypes from 'prop-types';

const FlashMessageContext = React.createContext({
    message: [],
    addMessage: (message) => {},
    addErrorMessage: (message, params = {}, timeout = null) => {},
    addSuccessMessage: (message, params = {}, timeout = null) => {}
});

const FlashMessage = (props) => {
    const [messages, setMessages] = useState([]);
    const addMessage = (message) => {
        const type = isString(message) ? 'info' : (message.type ?? 'info')
        const value = isString(message) ? message : message.message;
        const timeout = isString(message) ? null : (message?.timeout);
        const params = isString(message) ? {} : message?.params;
        const messagesClone = cloneDeep(messages);
        messagesClone.push({
            message: value,
            type: type,
            params: params,
            timeout: timeout
        });
        setMessages(messagesClone);
    }
    const addErrorMessage = (message, params = {}, timeout = null) => {
        addMessage({
            message: message,
            params: params,
            type: 'danger',
            timeout: timeout
        })
    }

    const addSuccessMessage = (message, params = {}, timeout = null) => {
        addMessage({
            message: message,
            type: 'info',
            params: params,
            timeout: timeout
        })
    }

    
    const memoizedValue = useMemo(
        () => ({
            messages,
            addMessage,
            addErrorMessage,
            addSuccessMessage
        }),
        [messages, addMessage, addErrorMessage, addSuccessMessage],
    );

    return (
        <FlashMessageContext.Provider value={memoizedValue}>
            {
                Array.isArray(messages) && messages?.map((message, index) => (
                    <div className={'messages'}>
                        <MessageItem
                            message={message.message}
                            type={message.type}
                            timeout={message.timeout}
                            params={message.params}
                            key={`flash-message-${index}`} />
                
                    </div>
                ))
            }
            {props.children}
        </FlashMessageContext.Provider>
    )
}


FlashMessage.propTypes = {
    children: PropTypes.element
};

export {
    FlashMessage,
    FlashMessageContext
};
