import { useEffect } from "react"
import PropTypes from 'prop-types'


//Hook to hide an element after clicking outside of it
//Takes a ref, and the setter is the function that changes its visibility
const useClickingOutside = (ref, setter) => {
  useEffect(() => {
    try{

      if(!ref || !ref.current) 
        return
      const outsideClickHandler = (e) => {
        if (!ref.current.contains(e.target)) {
          setter(false)
        }
      }
      document.addEventListener("mousedown", outsideClickHandler)
      return () => {
        document.removeEventListener("mousedown", outsideClickHandler)
      }
    }

    catch(err)
    {
      console.log(err, 'In useClickingOutside hook')
    }
  })
}

useClickingOutside.propTypes  = {
  ref: PropTypes.object.isRequired,
  setter: PropTypes.func.isRequired
}

export default useClickingOutside