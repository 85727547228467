import { authURL } from '../Config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setToken, clearToken } from '../Redux/features/auth/authSlice';
import { Mutex } from 'async-mutex';
import tokenRepository from "./tokenRepository";
// import {store} from "../../Redux/store";
// import {RESET_STATE_ACTION_TYPE} from "../../Redux/actions";
import {deleteAllCookies} from '../utils/cookieUtils'
import lscache from "lscache";
import {BroadcastChannel} from "broadcast-channel";
import {store} from "../Redux/store";
import {RESET_STATE_ACTION_TYPE} from "../Redux/actions";


// Define a service using a base URL and expected endpoints
const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${authURL}`,
    prepareHeaders: (headers) => {
      headers.set('X-engage-initiator', 'frontend');
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTokenByUser: builder.mutation({
      query: (payload) => ({
        url: '/auth',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response) => response,
    }),
  }),
});
const openBaseQuery = ({ baseUrl }) => fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json');

    return headers;
  },
});

const mutex = new Mutex();
const secureBaseQuery = ({ baseUrl }) => async (args, api, extraOptions) => {
  const safeArgs = args.url ? args : { url: args };

  const config = {
    baseUrl,
    prepareHeaders: (headers) => {
      if (tokenRepository.getToken()) {
        headers.set('Authorization', safeArgs?.headers?.Authorization || `Bearer ${tokenRepository.getToken()}`);
      }
      headers.set('accept', 'application/json');
      headers.set('X-engage-initiator', 'frontend');
      if ((args['method'] || '') === 'PATCH') {
        headers.set('Content-Type', 'application/merge-patch+json');
      } else {
        headers.set('Content-Type', 'application/json');
      }

      return headers;
    },
  };

  await mutex.waitForUnlock();

  const baseQuery = fetchBaseQuery({ ...safeArgs, ...config });
  let result = await baseQuery({ ...config, ...safeArgs }, api, extraOptions);

  if (result.error && result.error.status === 401) {
  // if (true) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        // Try to get a new token
        const { data: response = { data: {} } } = await openBaseQuery({ baseUrl: authURL })({
          url: '/refresh-token',
          method: 'POST',
          body: { refreshToken: tokenRepository.getRefreshToken() },
        }, api, extraOptions);

        const { data } = response;
        if (data.token) {
          // Store the new token
          api.dispatch(setToken({
            accessToken: data.token,
            refreshToken: data.refreshToken//tokenRepository.getRefreshToken(),
          }));
          tokenRepository.setToken(data.token);
          tokenRepository.setRefreshToken(data.refreshToken);

          config.prepareHeaders = (headers) => {
            headers.set('Authorization', `Bearer ${data.token}`);
            headers.set('accept', 'application/json');

            return headers;
          };

          result = await baseQuery({ ...safeArgs, ...config }, api, extraOptions);
        } else {
          tokenRepository.deleteAll();
          api.dispatch(clearToken());
          store.dispatch({ type: RESET_STATE_ACTION_TYPE });
          BroadcastLogout();
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

const logoutChannel = new BroadcastChannel('logout',{
  webWorkerSupport: true
});

export const BroadcastLogout = () => {
  localStorage.removeItem('persist:root');
  logoutChannel.postMessage('Logout');
  deleteAllCookies();
  lscache.flush();
  // localStorage.clear();
  window.location.href = `${window.location.origin}`;
};

export const BroadcastLogoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    BroadcastLogout();
    logoutChannel.close();
  };
};


export const { useGetTokenByUserMutation } = authApi;
export { openBaseQuery, secureBaseQuery, authApi };
