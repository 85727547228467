const languages = {
    ro: {
        value: 'ro',
        label :'Romana',
        flag: 'RO',
    },
    en: {
        value: 'en',
        label:'English',
        flag: 'US'
    }
};
export default languages;
