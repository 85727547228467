import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';


const LanguagesBox = ({setLanguage, setDropdownOpen, isDropdownOpen}) => {
  const styleBox = clsx('language-box-container', isDropdownOpen && 'language-box-container-transition')

  const handleLanguageClick = (language) => {
    setLanguage(language);
    setDropdownOpen(false)
  }

  const handleLanguageRo = () => handleLanguageClick("ro");
  const handleLanguageEn = () => handleLanguageClick("en");

  return (
    <div className={styleBox}>
      <div role='button' className='language-box-row' onClick={handleLanguageEn}>
        
        <div className='language-box-row-icon english-flag-bg'/>
        
        <div className='language-selector-text'>
          <FormattedMessage id='language.english'/>
        </div>
      </div>
      <hr/>
      <div role='button' className='language-box-row' onClick={handleLanguageRo}>
        <div className='language-box-row-icon romanian-flag-bg'/>
        <div className='language-selector-text' >
          <FormattedMessage id='language.romanian'/>
        </div>
        
      </div>
    </div>
  );
}

LanguagesBox.propTypes = {
  setLanguage: PropTypes.func.isRequired,
  setDropdownOpen: PropTypes.func.isRequired,
  isDropdownOpen:PropTypes.bool,
};
export default LanguagesBox;
