import { createSlice } from '@reduxjs/toolkit';
import {tournamentsApi} from "../../../Services/tournament";
const initialState = {
    items: {},
};

export const tournamentsSlice = createSlice({
    name: 'tournament',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            tournamentsApi.endpoints.getList.matchFulfilled,
            (state, { payload }) => {
                state.items = payload;
            },
        );
    },
});

// Action creators are generated for each case reducer function
export default tournamentsSlice.reducer;
