import React from 'react';
import { useLocation } from 'react-router-dom';
import intersection from 'lodash/intersection';
import useAuthContext from '../Hooks/useAuthContext';
import tokenRepository from "../Services/tokenRepository";
import Loading from '../Components/Loading';
import { routes } from "./Lazy";
import {store} from "../Redux/store";
import {RESET_STATE_ACTION_TYPE} from "../Redux/actions";
import {BroadcastLogout} from "../Services/auth";
import PropTypes from 'prop-types';


const pageLogout = () => {
  store.dispatch({ type: RESET_STATE_ACTION_TYPE });
  BroadcastLogout();
};

const RouteGuard = ({ children }) => {
  const { pathname } = useLocation();
  const {isLoggedIn} = useAuthContext();

  const userData = tokenRepository.getUserData();
  const roles = userData ? userData.roles : []

  const hasAccessRights = roles && Object.values(routes).find(
    (item) => pathname === item.path
      && intersection(roles, item.permissions).length > 0,
  );

  // if (!hasAccessRights || !isLoggedIn) {
  if (!isLoggedIn) {

    pageLogout();

    return (
      <Loading width={10} height={10} />
    );
  }

  return (children);
};

RouteGuard.propTypes = {
  children: PropTypes.element
};
export default RouteGuard;
