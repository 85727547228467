import { createSlice } from '@reduxjs/toolkit';
import {UserRoles} from "../../../Config/UserRoles";
import tokenRepository from "../../../Services/tokenRepository";

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: null,
    refreshToken: null,
    userId: null,
    email: null,
    roles: [UserRoles.PUBLIC],
  },
  reducers: {
    setToken(_state, action) {
      const { accessToken, refreshToken, userId } = action.payload;
      const {roles = [UserRoles.USER], email} = tokenRepository.parseJwt(accessToken);
      return {
        accessToken,
        userId,
        refreshToken,
        roles,
        email,
      };
    },
    clearToken() {
      return {
        accessToken: null,
        refreshToken: null,
        userId: null,
        email: null,
        roles: [UserRoles.PUBLIC],
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken, clearToken } = authSlice.actions;

export default authSlice.reducer;
