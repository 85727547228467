export const getErrors = (response = { message: null }) => {
    const { message, error = { errorMessage: null }, violations = [] } = response;
    let errors = [];

    if (message) {
        errors.push(message);
    }

    if (Array.isArray(violations) && violations.length > 0) {
        errors = [...errors, ...violations.map((violation) => violation.message)];
    } else if (error.errorMessage) {
        errors.push(error.errorMessage);
    }

    return errors;
};
