import React,{ useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { blueCamera, spinner, whiteCamera, whiteFolder, blueFolder, blueCrop, whiteCrop, blueBin, whiteBin, whiteReplace, blueReplace, closeButton, closeButtonHover, imageIcon, clipboard } from './Assets/Assets';

const ButtonFactory = ({ variant, image=undefined, children, onClick, className, disabled = false, isLoading = false, ref = null }) => {
  const [isHovered, setIsHovered] = useState(false);

  //Styles for the base button/ the button layout
  const buttonStyles = clsx(
    variant === "upload" && "button-upload button-upload-upload",
    variant === "viewFile" && "button-upload button-upload-view",
    variant === "generatePdf" && "footer-button footer-button-pdf justify-self-center",
    variant === "reimport" && "footer-button footer-button-reimport justify-self-center",
    variant === "browse" && "interaction-button",
    variant === "takePhoto" && "interaction-button",
    variant === "save" && "action-button action-button-save",
    variant === "cancel" && "action-button action-button-cancel",
    variant === "logout" && "login-button-logout",
    variant === "crop" && "edit-button",
    variant === "delete" && "edit-button",
    variant === "replace" && "edit-button",
    variant === "x" && "x-button",
    variant === "languageSwap" && "language-button",
    variant === "processImage" && "action-button processImage-button font-for-buttons",
    variant === "pgn" && "action-button pgn-button font-for-buttons",
    variant === "processing" && "action-button processing-button font-for-buttons",
    variant === 'clipboard' && 'x-button clipboard-hover',
    variant === 'retry' && 'button-upload action-button-cancel header-text',
    disabled && "disabledButton"
  )

let icon, iconHover
  switch (variant) {
    //In case it's a button that incorporates icons, set those icons
    case "browse":
      {
        if (icon !== blueFolder)
          icon = blueFolder
        iconHover = whiteFolder
        break
      }
    case "takePhoto":
      {
        if (icon !== blueCamera)
          icon = blueCamera
        iconHover = whiteCamera
        break
      }
    case "crop":
      {
        icon = blueCrop;
        iconHover = whiteCrop;
        break
      }
    case "delete":
      {
        icon = blueBin;
        iconHover = whiteBin;
        break
      }
    case "replace":
      {
        icon = blueReplace;
        iconHover = whiteReplace;
        break
      }
    case "x": {
      icon = closeButton;
      iconHover = closeButtonHover;
      break
    }
    case "viewFile": {
      icon = imageIcon;
      iconHover = imageIcon;
      break
    }
    case "clipboard": {
      icon = clipboard;
      iconHover = clipboard;
      break
    }
    default: {
      break
    }
  }

  const handleMouseEnter = (e) => {
    e.preventDefault()
    setIsHovered(true);
  };

  const handleMouseLeave = (e) => {
    e.preventDefault()
    setIsHovered(false);
  };

  return (
    <button
      onClick={onClick}
      className={[buttonStyles, className].join(" ")}
      onMouseEnter = {handleMouseEnter}
      onMouseLeave = {handleMouseLeave}
      disabled={disabled}
      ref={ref}
    >
      {image
        ? <div className="mx-3">
          <img src={image} className="rounded-image object-fit-cover" alt="icon" />
        </div>
        : <></>
      }
      
      {
        icon
          ? <div>
            {isHovered ? iconHover : icon}
          </div>
          : <></>
      }
      {children}
      {
        isLoading &&
        <div>
          {spinner}
        </div>
      }
    </button>
  );
}

ButtonFactory.propTypes = {
  variant: PropTypes.oneOf(["upload", "viewFile", "generatePdf", "reimport", "browse", "takePhoto", "save", "cancel", "logout", "crop", "delete", "replace", "x", "processing","clipboard","processImage","languageSwap","pgn"]),
  className: PropTypes.string,
  image: PropTypes.element,
  children: PropTypes.element,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

export default ButtonFactory;
