import React, { useContext } from "react";
import LanguageSwitch from "../Navigation/LanguageSwitch";
import {FormattedMessage} from "react-intl";
import { chessLogo } from "../UploadButton/Assets/Assets";
import ButtonFactory from "../UploadButton/ButtonFactory";
import { BroadcastLogout } from "../../Services/auth";
import { AuthContext } from "../../Context/AuthContext";
import HamburgerMenu from "../Navigation/Components/HamburgerMenu";
const Header = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const logout = () => BroadcastLogout();
    return (
        <div className="navbar-container">
            <div className="navbar-logo-container">
                <div className="navbar-logo">
                    {chessLogo}
                </div>
                <div className="navbar-logo-container-text-container">
                    <FormattedMessage id={'app.welcome'} />
                </div>
            </div>
            <div className="navbar-buttons-container">
                <LanguageSwitch />
                {isAuthenticated && <ButtonFactory variant="logout" onClick={logout} >
                    <FormattedMessage id={'logout.menu.title'} />
                </ButtonFactory>
                }
            </div>
            <HamburgerMenu />
        </div>
    )
}
export default Header;
