import React, {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setToken } from '../Redux/features/auth/authSlice';
import { RESET_STATE_ACTION_TYPE } from '../Redux/actions';
import tokenRepository from "../Services/tokenRepository";

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIAL':
      return {
        isAuthenticated: true,
        accountRoles: action.payload.accountRoles,
        isLoggedIn: true,
      };
    case 'LOGOUT':
      return {
        isAuthenticated: false,
        accountRoles: [],
        isLoggedIn: false,
      };
    default:
      return {
        isAuthenticated: false,
        isLoggedIn: false,
      };
    }
};

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const {
    userId, roles,
  } = useSelector((stateRtk) => stateRtk.auth);
  const [state, dispatchContext] = useReducer(reducer, {
    isAuthenticated: tokenRepository.getToken(),
    accountRoles: roles,
    isLoggedIn: tokenRepository.getToken(),
  });

  const initialize = useCallback(async () => {
    if (
        tokenRepository.getToken()
      && tokenRepository.getRefreshToken()
    ) {
      dispatch(setToken({
        accessToken: tokenRepository.getToken(),
        refreshToken: tokenRepository.getRefreshToken(),
      }));
    } else {
      dispatch({ type: RESET_STATE_ACTION_TYPE });
    }

    try {
      if (tokenRepository.getToken()) {
        const isLoggedIn = true;
        dispatchContext({
          type: 'INITIAL',
          payload: {
            isAuthenticated: isLoggedIn,
            accountRoles: roles,
          },
        });
      } else {
        dispatchContext({
          type: 'LOGOUT',
          payload: {
            isAuthenticated: false,
          },
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatchContext({
        type: 'LOGOUT',
        payload: {
          isAuthenticated: false,
        },
      });
    }
  }, [userId]);

  useEffect(() => {
    initialize();
  }, [
    initialize,
  ]);

  // LOGIN
  const login = useCallback(async ({
    accessToken,
    refreshToken,
    callback,
  }) => {
    dispatch(setToken({
      accessToken,
      refreshToken,
    }));
    tokenRepository.setToken(accessToken);
    tokenRepository.setRefreshToken(refreshToken)

    dispatchContext({
      type: 'INITIAL',
      payload: {
        accountRoles: roles,
      },
    });

    callback();
  }, []);

  // REGISTER
  const register = useCallback(async () => {
    // eslint-disable-next-line no-console
    console.log('register');
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('logout');
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isAuthenticated: state.isAuthenticated,
      isLoggedIn: state.isAuthenticated,
      accountRoles: state.accountRoles,
      login,
      register,
      logout,
    }),
    [
      state.isAuthenticated,
      state.accountRoles,
      login,
      logout,
      register,
    ],
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};
