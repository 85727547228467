export default {
    language: {
        romanian: 'Romanian',
        english: 'English'
    },
    app: {
        welcome: "Arad Open Grand Prix Classic",
        footer: {
            copyright: '© {year} Arnia Software. All rights reserved.'
        },
    },
    validation: {
        required: 'Required Field',
        password: {
            match: 'Passwords must match'
        }
    },
    ui: {
        camera: {
            "not-allowed-message": "Access to the camera is blocked. Please allow access to take photos",
            "take-photo": "Take photo"
        }
    },
    tournament: {
        menu: {
            title: 'Tournaments'
        },
        fields: {
            name: 'Name',
            round: 'Rounds'
        },
        ui: {
            camera: {
                "take-photo": "Take photo"
            }
        },
        game: {
            round: 'Round',
            download: 'Download',
            view: 'View',
            board: 'Bo.',
            white: 'White',
            black: 'Black',
            file: 'File',
            result: 'Result',
            name: 'Name',
            processImage: 'Process',
            reprocessImage: 'Reprocess',
            processing: 'Processing..',
            pgn: 'PGN',
            enableProcessing: 'Enable processing',
            no: "There are no games",
            results: "Results",
            noResults: "The results will appear after the end of each round",
            action: "Action",
            pgnCopied: 'PGN has been copied',
            pgnNotCopied: "PGN couldn't be copied",
            retry: 'Retry',
            pdfCopied: 'PDF was copied',
            pdfNotCopied: 'PDF could not be copied'
        },
        image: {
            view: 'View',
            upload: 'Photo',
            save: {
                success: 'File saved with succes',
                error: 'Something went wrong while saving the file'
            },
        },
        button: {
            save: 'Save' ,
            cancel: 'Cancel',
            crop: 'Crop',
            photo: 'Take photo',
            browse: 'Browse photo',
            reimport: 'Reimport round',
            pdf: 'Generate PDF',
            delete: 'Delete',
            replace: 'Replace'
        },
        pdf: {
            new: 'GENERATE A NEW PDF\n\nwith the latest changes',
            success: 'PDF generated successfully',
            error: 'Error generating the PDF file',
            view: 'View PDF',
            copy: 'Copy PDF link',
        },
        reimport: {
            success: 'The round was successfully reimported',
            error: 'The round was NOT reimported',
        },
        modal: {
            upload: "Drag & drop photo here",
            uploadInfo: "Upload a single photo for a player with all pages in order",
            supported: 'Supported files: PNG, JPG - max. 30 MB',
            or: 'OR',
            header: 'Upload photo',
            save: {
                success: 'Fisa a fost salvata cu succes',
                error: 'A aparut o eroare la salvarea fisei'
            },
        },
        modalEdit: {
            header: 'Edit file'
        },
        header: {
            title: "Arad Open Grand Prix Classic",
            chessresults: 'View on chess-results.com'
        },
        fileRejections: {
            "file-too-large" : "File is larger than 30 MB.",
            "file-invalid-type" : "File type must be *.jpg, *.jpeg, *.png",
            "too-many-files" : "We accept only one file of type *.jpg, *.jpeg, *.png"
        }
    },

    user: {
        actions: {
            'delete-confirm': 'Are you sure you want to delete the user "{firstName} {lastName}"'
        },
        add: {
            new: 'Add user',
        },
        buttons: {
            'add-new': "Add new User"
        },
        create: {
            new: 'Create new user'
        },
        dropdown: {
            empty: '--Select User--'
        },
        edit: {
            label: 'Edit user "{firstName} {lastName}"',
        },
        error: {
            'email-exists': 'Email address already exists'
        },
        fields: {
            'current-password': 'Current password',
            email: 'E-mail',
            firstName: 'First name',
            language: "Language",
            lastName: 'Last name',
            password: 'Password',
            're-password': 'Retype password',
            user: 'User'
        },
        info: {
            label: 'User info'
        },
        load: {
            missing: "Requested user does not exist",
            'load-error': 'There was an error retrieving the user: "{error}"'
        },
        menu: {
            title: 'Users'
        },
        "no-results": "There are no users",
        save: {
            success: 'User "{firstName} {lastName}" was saved successfully',
            error: 'There was an error saving the user: "{error}"'
        },
        view: {
            label: 'View user "{firstName} {lastName}"',
        },
    },
    'my-account': {
        'change-password': {
            error: 'There was an error changing your password: {error}',
            'incorrect-current-password': 'Current password is incorrect',
            label: 'Change password',
            success: 'Your password was changed successfully'
        },
        info: 'Account details',
        load: {
            error: 'There was a problem retrieving your account data: {error}'
        },
        menu: {
            title: 'My Account'
        },
        save: {
            success: 'Account details saved successfully.',
            error: 'There was an error saving account details: {error}'
        }
    },
    logout : {
        menu: {
            title: "Logout"
        }
    },
    login: {
        buttons: {
            login: 'Login'
        },
        fields: {
            email: 'Your e-mail address',
            password: 'Your password'
        },
        'forgot-password': {
            message: 'Forgot your password?',
            recover: 'Recover it'
        },
        invalid: {
            credentials: 'Invalid login credentials'
        },
        menu: {
            title: 'Login'
        },
        error: {
            'generic': 'Something went wrong please try again in few minutes.'
        },
    },
    register: {
        'existing-account': 'Already have an account?',
        label: 'Register',
        menu: {
            title: 'Register',
        },
        'no-account': 'You don\'t have an account yet ?',
        success: 'Your registration was successful. You will receive an email to set your password'

    },
    'recover-password': {
        label: 'Password recovery',
        login: 'Return to login page.',
        message: 'Enter the email address you used when you joined and we’ll send you instructions to reset your password.',
        recover: 'Recover',
        'success-message': 'We\'ve sent you an email with the next step!'
    },
    'reset-password': {
        buttons: {
            change: 'Change password'
        },
        error: 'There was an error while resetting the password',
        'missing-reset-code': 'Reset code is not valid',
        success: 'Your password was reset successfully',
        'title': 'Set Password'
    },
}
