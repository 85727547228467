import {createApi} from "@reduxjs/toolkit/query/react";
import {secureBaseQuery} from "./auth";
import {authURL} from "../Config";
import onQueryStarted from "./helpers/onQueryStarted";

export const gamesApi = createApi({
    reducerPath: 'gameApi',
    tagTypes: ['Games'],
    baseQuery: secureBaseQuery({
        baseUrl: `${authURL}`,
    }),
    endpoints: (builder) => {
        let endpoints = {};
        //update from chess results
        endpoints.update = builder.mutation({
            query: (payload) => ({
                url: `/games/${payload.id}`,
                method: 'PATCH',
                body: JSON.stringify(payload),
            }),
            invalidatesTags: ['Tournaments', 'Games'],
            onQueryStarted,
        })
        //get item
        endpoints.get = builder.query({
            query: (id) => ({
                url: `/games/${id}`,
                method: 'GET',
            }),
            providesTags: ['Games'],
        })
        //delete item
        endpoints.delete = builder.mutation({
            query: ({id}) => ({
                url: `/games/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Games', 'Tournaments'],
            onQueryStarted,
        });
        endpoints.updatePgn = builder.mutation({
            query: (gameData) => ({
                url: `/game/${gameData.id}/pgn`,
                method: 'PATCH',
                body: JSON.stringify(gameData),
            }),
            invalidatesTags: ['Games' ]
        })
        return endpoints;
    }
});

export const {
    useLazyGetQuery,
    useUpdateMutation,
    useDeleteMutation,
    useUpdatePgnMutation,
} = gamesApi;
