import { Alert } from "reactstrap";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from 'prop-types';


const MessageItem = (props) => {
    const {
        // index,
        type,
        message,
        params = {},
        dismissable = true,
        timeout = 5000
    } = props;
    
    const [isOpen, setIsOpen] = useState(true);
    const onDismiss = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        if (timeout !== false) {
            setTimeout(() => {
                setIsOpen(false)
            }, 5000);
        }
    }, [timeout])

    let alertProps = {
        isOpen: isOpen,
        color: type
    }
    if (dismissable) {
        alertProps.toggle = onDismiss
    }
    return (
        <Alert {...alertProps}>
            {message && <FormattedMessage id={message} values={params} />}
            {props.children}
        </Alert>
    )
}
MessageItem.propTypes = {
    type: PropTypes.string,
    message: PropTypes.string,
    params: PropTypes.object,
    dismissable: PropTypes.bool,
    timeout: PropTypes.number,
    children: PropTypes.element
};

export default MessageItem;
