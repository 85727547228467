import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { BroadcastLogout } from '../../../Services/auth';
import { AuthContext } from '../../../Context/AuthContext';
import { IntlContext } from '../../../Context/IntlWrapper';
import clsx from 'clsx';


const LanguageAndLogoutBox = ({toggle, isOpen}) => {
  const logout = () => BroadcastLogout();
  //Locale is the language that is currently selected
  const {locale, changeLanguage} = useContext(IntlContext );
  const setLanguage = (language) => changeLanguage(language);
  const styleBox = clsx('language-box-container', isOpen && 'language-box-container-transition')
  const { isAuthenticated } = useContext(AuthContext);


  const handleLanguageClick = (language) => {
    setLanguage(language);
    toggle()
  }
  const handleLogoutClick = () => {
    logout();
    toggle()
  }

  const handleLanguageRo = () => handleLanguageClick("ro");
  const handleLanguageEn = () => handleLanguageClick("en");

  return (
    <div className={styleBox}>
      <div role='button' className='language-box-row' onClick={handleLanguageEn}>

        <div className='language-box-row-icon english-flag-bg'/>

        <div className='language-selector-text'>
          <FormattedMessage id='language.english'/>
        </div>
      </div>
      <hr/>
      <div role='button' className='language-box-row' onClick={handleLanguageRo}>
        <div className='language-box-row-icon romanian-flag-bg'/>
        <div className='language-selector-text' >
          <FormattedMessage id='language.romanian'/>
        </div>
      </div>
      {isAuthenticated &&
      <>
      <hr/>
      <div role='button' className='language-box-row language-selector-text align-self-center ' onClick={handleLogoutClick}>
        <FormattedMessage id='logout.menu.title'/>
      </div>
      </>
      }
    </div>
  );
}

export default LanguageAndLogoutBox;
