import {isObject} from "lodash";

const flattenObject = (obj) => {
    return parseRecursive(obj, {}, '');
}

const parseRecursive = (obj, collector, prefix) => {
    for (let element in obj) {
        if (isObject(obj[element])) {
            collector = parseRecursive(obj[element], collector, prefix + (prefix !== '' ? '.' : '') + element);
        } else {
            collector[prefix + (prefix !== '' ? '.' : '') + element] = obj[element];
        }
    }
    return collector;
}

const groupBy = (array, property) => array.reduce((grouped, element) => ({
    ...grouped,
    [element[property]]: [...(grouped[element[property]] || []), element]
  }), {})

export { flattenObject, groupBy };
