import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {authURL} from "../Config";

export const publicTournamentApi = createApi({
    reducerPath: 'publicTournamentApi',
    tagTypes: ['TournamentResult'],
    baseQuery: fetchBaseQuery({ 
        baseUrl: `${authURL}`
    }),
    endpoints: (builder) => {
        let endpoints = {}; 
        //get TournamentRounds Results (pdf)
        endpoints.getTournamentResults = builder.query({
            query: () => ({
                url: `/results`,
                method: 'GET',
            }),
            providesTags: ['Tournaments'],
        }) 
        return endpoints;
    }
});

export const {
    useLazyGetTournamentResultsQuery
} = publicTournamentApi;
