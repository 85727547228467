import React from 'react';
import {useRoutes} from 'react-router-dom';
import RouteGuard from './RouteGuard';
import {DefaultLayout} from "../Components/Layout";
import routes from './routes';


const AppRoutes = () => useRoutes([
  {
    element: <DefaultLayout />,
    children: routes.public,
  },
  {
    element:
    <RouteGuard>
      <DefaultLayout />
    </RouteGuard>,
    children: routes.private
  }
]);
export default AppRoutes;
