import React, {Suspense} from "react";
import Loading from "./Loading";

const Loadable = (Component) => (props) => {
    return (
        <Suspense fallback={<Loading width={10} height={10} />}>
            <Component {...props} />
        </Suspense>
    );
};
export default Loadable;
